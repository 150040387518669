import React, { Component, Fragment } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import logoInk from '../assets/images/logo-ink.png';
import logoPaper from '../assets/images/logo-paper.png';

const Brand = ({ children }) => (
	<StaticQuery
		query={graphql`
			query BrandItemsQuery {
				allBrandItemsJson {
					edges {
						node {
							label
							link
						}
					}
				}
			}
		`}
		render={(data) => (
			<div className="brand">
				<Fragment>{getBrandLabels(data)}</Fragment>
			</div>
		)}
	/>
);

function getBrandLabels(data) {
	const brandItemsArray = [];
	data.allBrandItemsJson.edges.forEach((item) =>
		brandItemsArray.push(
			<Link to={item.node.link}>
				<img src={logoPaper} alt={item.node.logoText} />
				<img src={logoPaper} alt={item.node.logoText} />
				<span key={item.node.label}>{item.node.label}</span>
			</Link>
		)
	);
	return brandItemsArray;
}

export default Brand;
