import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Nav from '../components/nav';

import { HamburgerSpin } from 'react-animated-burgers';

function Navbar() {
	const [ toggleState, setToggleState ] = useState('menu-hidden');
	const [ isActive, setActiveState ] = useState(false);

	function toggle() {
		setToggleState(toggleState === 'menu-hidden' ? 'menu-open' : 'menu-hidden');
		setActiveState(isActive === false ? true : false);
		document.getElementById('layout').classList.toggle('noScroll');
	}

	return (
		<div className=" navbar">
			<div className="hamburger">
				<HamburgerSpin isActive={isActive} onClick={toggle} />
			</div>
			<div id="menu" className={`menu ${toggleState}`}>
				<Nav />
			</div>
		</div>
	);
}

export default Navbar;
