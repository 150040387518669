import React from 'react';

const BuiltByAdaptix = ({}) => (
	<div id="builtByAdaptix" style={{ margin: '15px 0 30px 0' }}>
		<p style={{ fontSize: '.8em' }} className="talk">
			Built by &nbsp;
			<a style={{ fontSize: '1em' }} target="_blank" href="https://adaptixcreative.com">
				Adaptix
			</a>
		</p>
	</div>
);

export default BuiltByAdaptix;
