import React from 'react';

import { ThemeToggler } from 'gatsby-plugin-dark-mode';

function ThemeSwitcher() {
	return (
		<div>
			<ThemeToggler>
				{({ theme, toggleTheme }) => (
					<label className="themeSwitcher">
						<input
							type="checkbox"
							onChange={(e) => toggleTheme(e.target.checked ? 'darkMode' : '')}
							checked={theme === 'darkMode'}
						/>
						<span className="slider round" />
					</label>
				)}
			</ThemeToggler>
		</div>
	);
}

export default ThemeSwitcher;

ThemeSwitcher.propTypes = {};
