// React / Gatsby imports
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// Style imports
import '../assets/css/styles.css';

// Font Awesome
import '../assets/fonts/font-awesome/css/all.css';

// Component imports
import Header from '../components/header';
import Footer from '../components/footer';

// Smooth scroll
if (typeof window !== 'undefined') {
	require('smooth-scroll')('a[href*="#"]');
}

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<Fragment>
			<Header siteTitle={data.site.siteMetadata.title} />
			<div id="layout">
				<main id="main">{children}</main>
				<Footer />
			</div>
		</Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
