import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

// Active styles
const activeStyles = {
	color: '#e5b557'
};

const Nav = ({ children }) => (
	<StaticQuery
		query={graphql`
			query NavItemsQuery {
				allNavItemsJson {
					edges {
						node {
							label
							link
						}
					}
				}
			}
		`}
		render={(data) => (
			<nav>
				<ul>{getNavLabels(data)}</ul>
			</nav>
		)}
	/>
);

function getNavLabels(data) {
	const navItemsArray = [];
	data.allNavItemsJson.edges.forEach((item) =>
		navItemsArray.push(
			<li key={item.node.label}>
				<Link activeStyle={activeStyles} to={item.node.link}>
					{item.node.label}
				</Link>
			</li>
		)
	);
	return navItemsArray;
}

export default Nav;
