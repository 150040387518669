import React from 'react';
import PropTypes from 'prop-types';

import Brand from '../components/brand';
import Navbar from '../components/navbar';
import ThemeSwitcher from '../components/themeSwitcher';

import Fade from 'react-reveal/Fade';

function Header() {
	return (
		<Fade>
			<header id="top">
				<div className="container">
					<div className="header__left">
						<Brand />
					</div>
					<div className="header__right">
						<Navbar />
						<ThemeSwitcher />
					</div>
				</div>
			</header>
		</Fade>
	);
}

export default Header;

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};
